import {Clients, Services, Contact, Header, Destinations} from '../components';

export function Home() {
  return (
    <main>
      <Header />
      <Clients />
      <Services />
      <Contact />
      <Destinations />
    </main>
  );
}
