import '../styles/contact.css';
import supportImg from '../assets/contact.png';
import {FaPhoneAlt} from 'react-icons/fa';

export function Contact() {
  return (
    <section className="container contact-section">
      <div className="contact-img">
        <img src={supportImg} alt="A lady as contact support" />
      </div>
      <div className="contact-details">
        <small className="small-heading">24/7 Support</small>
        <div className="phone-number-container">
          <FaPhoneAlt className="phone-icon" />
          <h2 className="phone-number"> 0208 144 2561</h2>
        </div>
        <p className="contact-para">
          Our Support Service is available 24 hours a day, 7 days a week to help
          you buy your tickets.
        </p>
      </div>
    </section>
  );
}
