import {Divider} from '../components';
import '../styles/pages/policy.css';

export function TermsAndConditions() {
  return (
    <section className="policy-section container">
      <h1>Terms And Conditions</h1>
      <Divider />
      <h2>1. Agreement to Terms</h2>
      <p>
        1.1 These Terms and Conditions constitute a legally binding agreement
        made between you, whether personally or on behalf of an entity (you),
        and Dreams Travels, located at Shine Building, Edream Travels, Harehills
        Road,Harehills Leeds. LS8 5HS (we, us), concerning your access to and
        use of the My Great New Website / App (https://www.dreamstravels.co.uk)
        website as well as any related applications (the Site).
      </p>
      <p>
        The Site provides the following services: We specialize in Pakistan
        Flights & Pakistan tours. Our unique booking model means that a
        dedicated Travel Expert take care of entire journey while keeping
        updated via email and mobile at each step of the booking process. Our
        travel experts offer honest and transparent advice on planning and
        booking trips as well as the travel experience itself. We will never
        over promise and under deliver, we would rather be honest and where
        there is doubt under-promise and over-deliver. Pakistan Tours are not
        like any other holiday and therefore we believe that a bespoke, one to
        one transparent service will ensure that the customer&apos;s
        expectations are clear. (Services). You agree that by accessing the Site
        and/or Services, you have read, understood, and agree to be bound by all
        of these Terms and Conditions.
      </p>
      <p>
        If you do not agree with all of these Terms and Conditions, then you are
        prohibited from using the Site and Services and you must discontinue use
        immediately. We recommend that you print a copy of these Terms and
        Conditions for future reference.
      </p>
      <p>
        1.2 The supplemental policies set out in Section 1.7 below, as well as
        any supplemental terms and condition or documents that may be posted on
        the Site from time to time, are expressly incorporated by reference.
      </p>
      <p>
        1.3 We may make changes to these Terms and Conditions at any time. The
        updated version of these Terms and Conditions will be indicated by an
        updated &quot;Revised&quot; date and the updated version will be
        effective as soon as it is accessible. You are responsible for reviewing
        these Terms and Conditions to stay informed of updates. Your continued
        use of the Site represents that you have accepted such changes.
      </p>
      <p>
        1.4 We may update or change the Site from time to time to reflect
        changes to our products, our users needs and/or our business priorities.
      </p>
      <p>
        1.5 Our site is directed to people residing in the United Kingdom. The
        information provided on the Site is not intended for distribution to or
        use by any person or entity in any jurisdiction or country where such
        distribution or use would be contrary to law or regulation or which
        would subject us to any registration requirement within such
        jurisdiction or country.
      </p>
      <p>
        1.6 The Site is intended for users who are at least 18 years old. If you
        are under the age of 18, you are not permitted to register for the Site
        or use the Services without parental permission.
      </p>
      <p>
        1.7 Additional policies which also apply to your use of the Site
        include:
      </p>
      <p>
        Our Privacy Notice https://www.dreamstravels.co.uk/privacy_policy, which
        sets out the terms on which we process any personal data we collect from
        you, or that you provide to us. By using the Site, you consent to such
        processing and you warrant that all data provided by you is accurate.
      </p>
      <p>
        Our Acceptable Use Policy
        https://www.dreamstravels.co.uk/privacy_policy, which sets out the
        permitted uses and prohibited uses of the Site. When using the Site, you
        must comply with this Acceptable Use Policy. Our Cookie Policy
        https://www.dreamstravels.co.uk/cookie_policy, which sets out
        information about the cookies on the Site.
      </p>
      <p>
        If you purchase services from the Site, our terms and conditions of
        supply will apply to these.
      </p>
      <p>
        Certain parts of this Site can be used only on payment of a fee. If you
        wish to use such Services, you will, in addition to our Terms and
        Conditions, also be subject to our Terms and conditions of supply.
      </p>
      <h2>2. Acceptable Use</h2>
      <p>
        2.1 Our full Acceptable Use Policy
        https://www.dreamstravels.co.uk/privacy_policy, sets out all the
        permitted uses and prohibited uses of this site.
      </p>
      <p>
        2.2 You may not access or use the Site for any purpose other than that
        for which we make the site and our services available. The Site may not
        be used in connection with any commercial endeavors except those that
        are specifically endorsed or approved by us.
      </p>
      <p>2.3 As a user of this Site, you agree not to:</p>
      <p>Use the Site to advertise or sell goods and services</p>
      <p>Advertise products or services not intended by us</p>
      <p>
        Falsely imply a relationship with us or another company with whom you do
        not have a relationship
      </p>
      <h2>3. Our content</h2>
      <p>
        3.1 Unless otherwise indicated, the Site and Services including source
        code, databases, functionality, software, website designs, audio, video,
        text, photographs, and graphics on the Site (Our Content) are owned or
        licensed to us, and are protected by copyright and trade mark laws.
      </p>
      <p>
        3.2 Except as expressly provided in these Terms and Conditions, no part
        of the Site, Services or Our Content may be copied, reproduced,
        aggregated, republished, uploaded, posted, publicly displayed, encoded,
        translated, transmitted, distributed, sold, licensed, or otherwise
        exploited for any commercial purpose whatsoever, without our express
        prior written permission.
      </p>
      <p>
        3.3 Provided that you are eligible to use the Site, you are granted a
        limited license to access and use the Site and Our Content and to
        download or print a copy of any portion of the Content to which you have
        properly gained access solely for your personal, non-commercial use.
      </p>
      <p>
        3.4 You shall not (a) try to gain unauthorized access to the Site or any
        networks, servers or computer systems connected to the Site; and/or (b)
        make for any purpose including error correction, any modifications,
        adaptions, additions or enhancements to the Site or Our Content,
        including the modification of the paper or digital copies you may have
        downloaded.
      </p>
      <p>
        3.5 We shall (a) prepare the Site and Our Content with reasonable skill
        and care; and (b) use industry standard virus detection software to try
        to block the uploading of content to the Site that contains viruses.
      </p>
      <p>
        3.6 The content on the Site is provided for general information only. It
        is not intended to amount to advice on which you should rely. You must
        obtain professional or specialist advice before taking, or refraining
        from taking, any action on the basis of the content on the Site.
      </p>
      <p>
        3.7 Although we make reasonable efforts to update the information on our
        site, we make no representations, warranties or guarantees, whether
        express or implied, that Our Content on the Site is accurate, complete
        or up to date.
      </p>
      <h2>4. Site Management</h2>
      <p>
        4.1 We reserve the right at our sole discretion, to (1) monitor the Site
        for breaches of these Terms and Conditions; (2) take appropriate legal
        action against anyone in breach of applicable laws or these Terms and
        Conditions; (3) remove from the Site or otherwise disable all files and
        content that are excessive in size or are in any way a burden to our
        systems; and (4) otherwise manage the Site in a manner designed to
        protect our rights and property and to facilitate the proper functioning
        of the Site and Services.
      </p>
      <p>
        4.2 We do not guarantee that the Site will be secure or free from bugs
        or viruses.
      </p>
      <p>
        4.3 You are responsible for configuring your information technology,
        computer programs and platform to access the Site and you should use
        your own virus protection software.
      </p>
      <h2>5. Modifications to and availability of the Site</h2>
      <p>
        5.1 We reserve the right to change, modify, or remove the contents of
        the Site at any time or for any reason at our sole discretion without
        notice. We also reserve the right to modify or discontinue all or part
        of the Services without notice at any time.
      </p>
      <p>
        5.2 We cannot guarantee the Site and Services will be available at all
        times. We may experience hardware, software, or other problems or need
        to perform maintenance related to the Site, resulting in interruptions,
        delays, or errors. You agree that we have no liability whatsoever for
        any loss, damage, or inconvenience caused by your inability to access or
        use the Site or Services during any downtime or discontinuance of the
        Site or Services. We are not obliged to maintain and support the Site or
        Services or to supply any corrections, updates, or releases.
      </p>
      <p>
        5.3 There may be information on the Site that contains typographical
        errors, inaccuracies, or omissions that may relate to the Services,
        including descriptions, pricing, availability, and various other
        information. We reserve the right to correct any errors, inaccuracies,
        or omissions and to change or update the information at any time,
        without prior notice.
      </p>
      <h2>6 Disclaimer/Limitation of Liability</h2>
      <p>
        The Site and Services are provided on an as-is and as-available basis.
        You agree that your use of the Site and/or Services will be at your sole
        risk except as expressly set out in these Terms and Conditions. All
        warranties, terms, conditions and undertakings, express or implied
        (including by statute, custom or usage, a course of dealing, or common
        law) in connection with the Site and Services and your use thereof
        including, without limitation, the implied warranties of satisfactory
        quality, fitness for a particular purpose and non-infringement are
        excluded to the fullest extent permitted by applicable law.
      </p>
      <p>
        We make no warranties or representations about the accuracy or
        completeness of the Site&apos;s content and are not liable for any (1)
        errors or omissions in content:
      </p>
      <p>errors or omissions in content</p>
      <p>
        any unauthorized access to or use of our servers and/or any and all
        personal information and/or financial information stored on our server;
      </p>
      <p>
        any interruption or cessation of transmission to or from the site or
        services; and/or
      </p>
      <p>
        any bugs, viruses, trojan horses, or the like which may be transmitted
        to or through the site by any third party. We will not be responsible
        for any delay or failure to comply with our obligations under these
        Terms and Conditions if such delay or failure is caused by an event
        beyond our reasonable control.
      </p>
      <p>Our responsibility for loss or damage suffered by you:</p>
      <h2>Whether you are a consumer or a business user:</h2>
      <p>
        We do not exclude or limit in any way our liability to you where it
        would be unlawful to do so. This includes liability for death or
        personal injury caused by our negligence or the negligence of our
        employees, agents or subcontractors and for fraud or fraudulent
        misrepresentation.
      </p>
      <p>
        {' '}
        If we fail to comply with these Terms and Conditions, we will be
        responsible for loss or damage you suffer that is a foreseeable result
        of our breach of these Terms and Conditions, but we would not be
        responsible for any loss or damage that were not foreseeable at the time
        you started using the Site/Services.
      </p>
      <p>
        Notwithstanding anything to the contrary contained in the
        Disclaimer/Limitation of Liability section, our liability to you for any
        cause whatsoever and regardless of the form of the action, will at all
        times be limited to a total aggregate amount equal to the greater of (a)
        the sum of £ 10 or (b) the amount paid, if any, by you to us for the
        Services/Site during the six (6) month period prior to any cause of
        action arising.
      </p>
      <h2>If you are a business user:</h2>
      <p>
        We will not be liable to you for any loss or damage, whether in
        contract, tort (including negligence), breach of statutory duty, or
        otherwise, even if foreseeable, arising under or in connection with:
      </p>
      <p>use of, or inability to use, our Site/Services; or</p>
      <p>use of or reliance on any content displayed on our Site.</p>
      <h2>In particular, we will not be liable for:</h2>
      <p>business interruption;</p>
      <p>loss of anticipated savings;</p>
      <p>loss of business opportunity, goodwill or reputation; or</p>
      <p>any indirect or consequential loss or damage.</p>
      <h2>7. Term and Termination</h2>
      <p>
        7.1 These Terms and Conditions shall remain in full force and effect
        while you use the Site or Services or are otherwise a user of the Site,
        as applicable. You may terminate your use or participation at any time,
        for any reason, by following the instructions for terminating user
        accounts in your account settings, if available, or by contacting us at
        info@dreamstravels.co.uk.
      </p>
      <p>
        7.2 Without limiting any other provision of these Terms and Conditions,
        we reserve the right to, in our sole discretion and without notice or
        liability, deny access to and use of the Site and the Services
        (including blocking certain IP addresses), to any person for any reason
        including without limitation for breach of any representation, warranty
        or covenant contained in these Terms and Conditions or of any applicable
        law or regulation.
      </p>
      <p>
        If we determine, in our sole discretion, that your use of the
        Site/Services is in breach of these Terms and Conditions or of any
        applicable law or regulation, we may terminate your use or participation
        in the Site and the Services or delete any content or information that
        you posted at any time, without warning, in our sole discretion.
      </p>
      <p>
        7.3 If we terminate or suspend your account for any reason set out in
        this Section 9, you are prohibited from registering and creating a new
        account under your name, a fake or borrowed name, or the name of any
        third party, even if you may be acting on behalf of the third party. In
        addition to terminating or suspending your account, we reserve the right
        to take appropriate legal action, including without limitation pursuing
        civil, criminal, and injunctive redress.
      </p>
      <h2>8. General</h2>
      <p>
        8.1 Visiting the Site, sending us emails, and completing online forms
        constitute electronic communications. You consent to receive electronic
        communications and you agree that all agreements, notices, disclosures,
        and other communications we provide to you electronically, via email and
        on the Site, satisfy any legal requirement that such communication be in
        writing.
      </p>
      <p>
        You hereby agree to the use of electronic signatures, contracts, orders
        and other records and to electronic delivery of notices, policies and
        records of transactions initiated or completed by us or via the Site.
        You hereby waive any rights or requirements under any statutes,
        regulations, rules, ordinances or other laws in any jurisdiction which
        require an original signature or delivery or retention of non-electronic
        records, or to payments or the granting of credits by other than
        electronic means.
      </p>
      <p>
        8.2 These Terms and Conditions and any policies or operating rules
        posted by us on the Site or in respect to the Services constitute the
        entire agreement and understanding between you and us.
      </p>
      <p>
        8.3 Our failure to exercise or enforce any right or provision of these
        Terms and Conditions shall not operate as a waiver of such right or
        provision.
      </p>
      <p>
        8.4 We may assign any or all of our rights and obligations to others at
        any time.
      </p>
      <p>
        8.5 We shall not be responsible or liable for any loss, damage, delay or
        failure to act caused by any cause beyond our reasonable control.
      </p>
      <p>
        8.6 If any provision or part of a provision of these Terms and
        Conditions is unlawful, void or unenforceable, that provision or part of
        the provision is deemed severable from these Terms and Conditions and
        does not affect the validity and enforceability of any remaining
        provisions.
      </p>
      <p>
        8.7 There is no joint venture, partnership, employment or agency
        relationship created between you and us as a result of these Terms and
        Conditions or use of the Site or Services.
      </p>
      <p>
        8.8 For consumers only - Please note that these Terms and Conditions,
        their subject matter and their formation, are governed by English law.
        You and we both agree that the courts of England and Wales will have
        exclusive jurisdiction expect that if you are a resident of Northern
        Ireland you may also bring proceedings in Northern Ireland, and if you
        are resident of Scotland, you may also bring proceedings in Scotland. If
        you have any complaint or wish to raise a dispute under these Terms and
        Conditions or otherwise in relation to the Site please follow this link
        http://ec.europa.eu/odr.
      </p>
      <p>
        8.9 A person who is not a party to these Terms and Conditions shall have
        no right under the Contracts (Rights of Third Parties) Act 1999 to
        enforce any term of these Terms and Conditions.
      </p>
      <p>
        8.10 In order to resolve a complaint regarding the Services or to
        receive further information regarding use of the Services, please
        contact us by email at info@dreamstravels.co.uk or by post to:
      </p>
      <p>Dreams Travels</p>
      <p>Shine Building</p>
      <p>Harehills Road,Harehills Leeds. LS8 5HS</p>
    </section>
  );
}
