import '../styles/flights.css';
import { FaArrowRight } from 'react-icons/fa';
import defaultLogo from '../assets/airline-logos/default.png';

// Type for the flight data
type FlightData = {
  id: string;
  type: string;
  attributes: {
    airline: string;
    arrival: string;
    departure: string;
    fare: string;
  };
};

// Dynamically import all logos from the folder using Webpack's `require.context`
const logoContext = require.context('../assets/airline-logos', false, /\.png$/);

const logos: { [key: string]: string } = {};

// Map all logos to their respective airline names
logoContext.keys().forEach((filename: string) => {
  const airlineName = filename.replace('./', '').replace('.png', ''); // Extract the airline name from the filename
  logos[airlineName] = logoContext(filename); // Store the logo path in the logos object
});

export function Flights(_props: { flightData: FlightData[] }) {
  return (
    <section className="container">
      <div className="all-flights-section">
        {_props.flightData?.map((data, index) => {
          const airlineLogo = logos[data.attributes.airline] || defaultLogo;
          return (
            <div className="flight-card flex-row" key={index}>
              <img
                src={airlineLogo}
                alt="flight airline logo"
                className="flight-airline-logo"
              />
              <div className="flex-column">
                <p className="flight-departure-date"></p>
                <div className="flex-row gap-25">
                  <div className="flex-column">
                    <h3>{data.attributes.departure}</h3>
                  </div>
                  <FaArrowRight />
                  <div className="flex-column">
                    <h3>{data.attributes.arrival}</h3>
                  </div>
                </div>
              </div>
              <div className="flex-column flight-price-section">
                <button>&pound;{data.attributes.fare}</button>
                <p>Return</p>
                <p>Rtn, Incl, Taxes</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}
