export async function apiRequest(
  url: string,
  body: string,
  method: string = 'POST',
) {
  let message, response, role;

  try {
    response = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: body,
    });

    const data = await response.json();
    role = data.user?.data?.attributes?.role;

    localStorage.setItem('token', data?.token);

    if (!response.ok) {
      message = data.status.message || 'Something went wrong';
      throw new Error(`Error: ${response.status}`);
    }
  } catch (error) {
    console.error('Error:', error);
  }

  return {message, response, role};
}
