import 'bulma/css/bulma.min.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

import {NavBar, Footer} from './components/';
import {
  Home,
  AboutUs,
  ContactUs,
  CallBack,
  Account,
  Tickets,
  Flight,
  PrivacyPolicy,
  CookiePolicy,
  TermsAndConditions,
} from './pages';

import {PrivateRoute} from './utils/PrivateRoute';
import {AdminLayout} from './admin/AdminLayout';
import {AdminDashboard} from './admin/AdminDashboard';
import {AdminCities} from './admin/AdminCities';
import {AdminFileUpload} from './admin/AdminFileUpload';

function AppContent() {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');

  return (
    <>
      {!isAdminRoute && <NavBar />}
      <div>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/flight/" element={<Flight />} />
          <Route path="/flight/:city" element={<Flight />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/request-callback" element={<CallBack />} />
          <Route path="/register" element={<Account />} />
          <Route path="/tickets" element={<Tickets />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />

          {/* Admin Routes */}
          <Route
            path="/admin/*"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Routes>
                    <Route path="dashboard" element={<AdminDashboard />} />
                    <Route path="countries" element={<AdminCities />} />
                    <Route path="upload-file" element={<AdminFileUpload />} />
                  </Routes>
                </AdminLayout>
              </PrivateRoute>
            }
          />
        </Routes>
      </div>
      {!isAdminRoute && <Footer />}
    </>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}
export default App;
