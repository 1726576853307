import '../styles/destinations.css';
import {FaArrowRight} from 'react-icons/fa';
import {Link} from 'react-router-dom';

import banjul from '../assets/destinations/banjul.jpg';
import capetown from '../assets/destinations/capetown.jpg';
import dubai from '../assets/destinations/dubai.jpg';
import istambul from '../assets/destinations/istanbul.jpg';
import newyork from '../assets/destinations/newyork.jpg';
import orlando from '../assets/destinations/orlando.jpg';
import paris from '../assets/destinations/paris.jpg';
import sansfrancisco from '../assets/destinations/sanfrancisco.jpg';
import switzerland from '../assets/destinations/switzerland.jpg';
import venice from '../assets/destinations/venice.jpg';
import barcelona from '../assets/destinations/barcelona.jpg';

export function Destinations() {
  return (
    <section className="destination-section">
      <blockquote>
        &quot; Air tickets displayed are based on the lowest price obtained from
        search through our search engine. As fares can fluctuate, there is no
        guarantee that a ticket will be available at the price stated. If the
        stated fare no longer be available, we will offer you the cheapest
        alternative. &quot;
      </blockquote>
      <small>Hot Deals</small>
      <h2>Popular Destinations</h2>
      <div className="divider"></div>
      <section className="destination-columns">
        <div className="column">
          <Link
            to="flight/barcelona-BCN"
            target="blank"
            className="destination">
            <img src={barcelona} alt="barcelona" className="destination-img" />
            <div className="overlay"></div>
            <div className="destination-details">
              <h3>Barcelona - &pound;321</h3>
              <div className="destination-text">
                <p>
                  Barcelona is the capital city of the autonomous community of
                  Catalonia in the Kingdom of Spain.
                </p>
                <FaArrowRight className="right-icon" />
              </div>
            </div>
          </Link>
          <Link
            to="/flight/switzerland-ZRH"
            target="blank"
            className="destination">
            <img
              src={switzerland}
              alt="switzerland"
              className="destination-img"
            />
            <div className="overlay"></div>
            <div className="destination-details">
              <h3>Switzerland - &pound;150</h3>
              <div className="destination-text">
                <p>
                  Switzerland officially the Swiss Confederation, is a federal
                  republic in Europe.
                </p>
                <FaArrowRight className="right-icon" />
              </div>
            </div>
          </Link>
          <Link
            to="/flight/newyork-NYC"
            target="blank"
            className="destination hidden-12">
            <img src={newyork} alt="newyork" className="destination-img" />
            <div className="overlay"></div>
            <div className="destination-details">
              <h3>New York - &pound;390</h3>
              <div className="destination-text">
                <p>New York is a state in the northeastern United States.</p>
                <FaArrowRight className="right-icon" />
              </div>
            </div>
          </Link>
          <Link
            to="/flight/orlando-ORL"
            target="blank"
            className="destination hidden-12">
            <img src={orlando} alt="orlando" className="destination-img" />
            <div className="overlay"></div>
            <div className="destination-details">
              <h3>Orlando - &pound;600</h3>
              <div className="destination-text">
                <p>
                  Orlando is a city in the U.S. state of Florida and the county
                  seat of Orange County.
                </p>
                <FaArrowRight className="right-icon" />
              </div>
            </div>
          </Link>
          <Link
            to="/flight/venice-VCE"
            target="blank"
            className="destination hidden-9">
            <img src={venice} alt="venice" className="destination-img" />
            <div className="overlay"></div>
            <div className="destination-details">
              <h3>Venice - &pound;69</h3>
              <div className="destination-text">
                <p>
                  Venice is a city in northeastern Italy and the capital of the
                  Veneto region.
                </p>
                <FaArrowRight className="right-icon" />
              </div>
            </div>
          </Link>
          <Link
            to="/flight/istanbul-IST"
            target="blank"
            className="destination hidden-9">
            <img src={istambul} alt="istambul" className="destination-img" />
            <div className="overlay"></div>
            <div className="destination-details">
              <h3>Istanbul - &pound;195</h3>
              <div className="destination-text">
                <p>
                  Istanbul historically known as Constantinople and Byzantium,
                  is the most populous city in Turkey.
                </p>
                <FaArrowRight className="right-icon" />
              </div>
            </div>
          </Link>
        </div>
        <div className="column hidden-8">
          <Link
            to="/flight/CapeTown-CPT"
            target="blank"
            className="destination">
            <img src={capetown} alt="capetown" className="destination-img" />
            <div className="overlay"></div>
            <div className="destination-details">
              <h3>Cape Town - &pound;475</h3>
              <div className="destination-text">
                <p>
                  Cape Town is a port city on South Africa’s southwest coast.
                </p>
                <FaArrowRight className="right-icon" />
              </div>
            </div>
          </Link>
          <Link to="/flight/venice-VCE" target="blank" className="destination">
            <img src={venice} alt="venice" className="destination-img" />
            <div className="overlay"></div>
            <div className="destination-details">
              <h3>Venice - &pound;69</h3>
              <div className="destination-text">
                <p>
                  Venice is a city in northeastern Italy and the capital of the
                  Veneto region.
                </p>
                <FaArrowRight className="right-icon" />
              </div>
            </div>
          </Link>
          <Link
            to="/flight/istanbul-IST"
            target="blank"
            className="destination hidden-12">
            <img src={istambul} alt="istambul" className="destination-img" />
            <div className="overlay"></div>
            <div className="destination-details">
              <h3>Istanbul - &pound;195</h3>
              <div className="destination-text">
                <p>
                  Istanbul historically known as Constantinople and Byzantium,
                  is the most populous city in Turkey.
                </p>
                <FaArrowRight className="right-icon" />
              </div>
            </div>
          </Link>
        </div>
        <div className="column">
          <Link
            to="/flight/CapTown-CPT"
            target="blank"
            className="destination hidden-9">
            <img src={capetown} alt="capetown" className="destination-img" />
            <div className="overlay"></div>
            <div className="destination-details">
              <h3>Cape Town - &pound;475</h3>
              <div className="destination-text">
                <p>
                  Cape Town is a port city on South Africa’s southwest coast.
                </p>
                <FaArrowRight className="right-icon" />
              </div>
            </div>
          </Link>
          <Link
            to="/flight/newyork-NY"
            target="blank"
            className="destination hidden-full">
            <img src={newyork} alt="newyork" className="destination-img" />
            <div className="overlay"></div>
            <div className="destination-details">
              <h3>New York - &pound;390</h3>
              <div className="destination-text">
                <p>New York is a state in the northeastern United States.</p>
                <FaArrowRight className="right-icon" />
              </div>
            </div>
          </Link>
          <Link
            to="/flight/orlando-ORL"
            target="blank"
            className="destination hidden-full">
            <img src={orlando} alt="orlando" className="destination-img" />
            <div className="overlay"></div>
            <div className="destination-details">
              <h3>Orlando - &pound;600</h3>
              <div className="destination-text">
                <p>
                  Orlando is a city in the U.S. state of Florida and the county
                  seat of Orange County.
                </p>
                <FaArrowRight className="right-icon" />
              </div>
            </div>
          </Link>
          <Link
            to="/flight/SanFrancisco-SFO"
            target="blank"
            className="destination">
            <img
              src={sansfrancisco}
              alt="sansfrancisco"
              className="destination-img"
            />
            <div className="overlay"></div>
            <div className="destination-details">
              <h3>San Francisco - &pound;570</h3>
              <div className="destination-text">
                <p>
                  The cultural, commercial, and financial center of Northern
                  California.
                </p>
                <FaArrowRight className="right-icon" />
              </div>
            </div>
          </Link>
          <Link
            to="/flight/Dubai-DXB"
            target="blank"
            className="destination hidden-12">
            <img src={dubai} alt="dubai" className="destination-img" />
            <div className="overlay"></div>
            <div className="destination-details">
              <h3>Dubai - &pound;365</h3>
              <div className="destination-text">
                <p>
                  Dubai is the largest and most populous city in the United Arab
                  Emirates (UAE).
                </p>
                <FaArrowRight className="right-icon" />
              </div>
            </div>
          </Link>
          <Link
            to="/flight/Paris-PAR"
            target="blank"
            className="destination hidden-12">
            <img src={paris} alt="paris" className="destination-img" />
            <div className="overlay"></div>
            <div className="destination-details">
              <h3>Paris - &pound;79</h3>
              <div className="destination-text">
                <p>Paris is the capital and most populous city of France.</p>
                <FaArrowRight className="right-icon" />
              </div>
            </div>
          </Link>
          <Link
            to="/flight/Banjul-BJL"
            target="blank"
            className="destination hidden-12">
            <img src={banjul} alt="banjul" className="destination-img" />
            <div className="overlay"></div>
            <div className="destination-details">
              <h3>Banjul - &pound;535</h3>
              <div className="destination-text">
                <p>
                  Banjul, officially the City of Banjul and formerly known as
                  Bathurst, is the capital of The Gambia.
                </p>
                <FaArrowRight className="right-icon" />
              </div>
            </div>
          </Link>
        </div>
        <div className="column  hidden-full">
          <Link
            to="/flight/Dubai-DXB"
            target="blank"
            className="destination hidden-full">
            <img src={dubai} alt="dubai" className="destination-img" />
            <div className="overlay"></div>
            <div className="destination-details">
              <h3>Dubai - &pound;365</h3>
              <div className="destination-text">
                <p>
                  Dubai is the largest and most populous city in the United Arab
                  Emirates (UAE).
                </p>
                <FaArrowRight className="right-icon" />
              </div>
            </div>
          </Link>
          <Link
            to="/flight/istanbul-IST"
            target="blank"
            className="destination hidden-full">
            <img src={istambul} alt="istambul" className="destination-img" />
            <div className="overlay"></div>
            <div className="destination-details">
              <h3>Istanbul - &pound;195</h3>
              <div className="destination-text">
                <p>
                  Istanbul historically known as Constantinople and Byzantium,
                  is the most populous city in Turkey.
                </p>
                <FaArrowRight className="right-icon" />
              </div>
            </div>
          </Link>
        </div>
        <div className="column  hidden-full">
          <Link
            to="/flight/Paris-PAR"
            target="blank"
            className="destination hidden-full">
            <img src={paris} alt="paris" className="destination-img" />
            <div className="overlay"></div>
            <div className="destination-details">
              <h3>Paris - &pound;79</h3>
              <div className="destination-text">
                <p>Paris is the capital and most populous city of France.</p>
                <FaArrowRight className="right-icon" />
              </div>
            </div>
          </Link>
          <Link
            to="/flight/Banjul-BJL"
            target="blank"
            className="destination hidden-full">
            <img src={banjul} alt="banjul" className="destination-img" />
            <div className="overlay"></div>
            <div className="destination-details">
              <h3>Banjul - &pound;535</h3>
              <div className="destination-text">
                <p>
                  Banjul, officially the City of Banjul and formerly known as
                  Bathurst, is the capital of The Gambia.
                </p>
                <FaArrowRight className="right-icon" />
              </div>
            </div>
          </Link>
        </div>
      </section>
    </section>
  );
}
