import {useState} from 'react';
import '../styles/pages/contact-us.css';
import {apiRequest} from '../utils/apiRequests';
import {FaPhoneAlt, FaEnvelope, FaCalendarAlt, FaMap} from 'react-icons/fa';
import {Divider, Input} from '../components';

const initialState = {
  name: '',
  email: '',
  telephone: '',
  message: '',
};

export function ContactUs() {
  const [contact, setContact] = useState(initialState);
  const [loading, setLoading] = useState(false);

  function handleInputChange(
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) {
    setContact({...contact, [e.target.name]: e.target.value});
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setContact(initialState);

    const body = JSON.stringify({contact_request: contact});
    await apiRequest(
      'https://dream-travels-backend-9eaa9bf82980.herokuapp.com/api/v1/contact_requests',
      body,
    );
    setLoading(false);
    alert("Thanks for Contacting. We'll get back to you as soon as possible.");
  };

  return (
    <main className="container contact">
      <div>
        <h1>Get in Touch</h1>
        <Divider />
        <p>
          You can contact us any way that is convenient for you. We are
          available 24/7 via call & email. You can also use a quick contact form
          below or visit our office personally. We would be happy to answer your
          questions.
        </p>
        <form action="submit" onSubmit={handleSubmit}>
          <Input
            label="Your Name"
            type="text"
            name="name"
            isRequired={true}
            onChange={handleInputChange}
            value={contact.name}
          />
          <Input
            label="Your Email"
            type="email"
            name="email"
            isRequired={true}
            onChange={handleInputChange}
            value={contact.email}
          />
          <Input
            label="Your Telephone"
            type="text"
            name="telephone"
            isRequired={true}
            onChange={handleInputChange}
            value={contact.telephone}
          />
          <div className="message">
            <label htmlFor="message" className="label">
              Message
            </label>
            <textarea
              name="message"
              id="message"
              required
              className="textarea"
              value={contact.message}
              onChange={e => handleInputChange(e)}></textarea>
          </div>
          <button
            className={`submit-btn ${loading ? 'disabled-button' : ''}`}
            type="submit"
            disabled={loading}>
            Submit
          </button>
        </form>
      </div>
      <div className="contact-details">
        <div>
          <h2>Phones</h2>
          <p>
            <FaPhoneAlt className="icon" />
            <span>0208 144 2561</span>
          </p>
        </div>
        <div>
          <h2>Address</h2>
          <p>
            <FaMap className="icon" />
            <span>
              Shine Building Edream Travels Harehills Road,Harehills Leeds. LS8
              5HS
            </span>
          </p>
        </div>
        <div>
          <h2>Email</h2>
          <p>
            <FaEnvelope className="icon" />
            <span>info@dreamstravels.co.uk</span>
          </p>
        </div>
        <div>
          <h2>Opening Hours</h2>
          <p>
            <FaCalendarAlt className="icon" />
            <span>Timing 8:00am to 11:30pm Week days</span>
          </p>
        </div>
      </div>
    </main>
  );
}
