/* eslint-disable no-console */
import {FaPlane, FaPhoneAlt} from 'react-icons/fa';
import '../styles/pages/tickets.css';

import {Link, useLocation, useSearchParams} from 'react-router-dom';

type TwoWayData = {
  id: string;
  type: string;
  attributes: {
    airline: string;
    departure1: string;
    arrival1: string;
    departure2: string;
    arrival2: string;
    fare: string;
  };
};

interface FlightData {
  from: string;
  to: string;
  cabinClass: string;
  departure: string;
  returnDate: string;
  adults: number;
  children: number;
  infants: number;
}

export function Tickets() {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const data = location.state?.data.data;

  const rawParams = searchParams.keys().next().value;

  let flightData: FlightData = {
    from: '',
    to: '',
    cabinClass: '',
    departure: '',
    returnDate: '',
    adults: 0,
    children: 0,
    infants: 0,
  };

  try {
    flightData = rawParams ? JSON.parse(decodeURIComponent(rawParams)) : {};
  } catch (error) {
    console.error('Error parsing query parameters:', error);
  }

  return (
    <div className="tickets-page">
      <section className="container">
          {flightData.from && flightData.to && (
          <h1>
            {flightData.from}, {flightData.to}
          </h1>
          )}
        <div className="flex-column gap-2">
          {data.map((data: TwoWayData, index: number) => {
            return (
              <div className="ticket" key={index}>
                <div className="ticket-heading">
                  <p>TAP Air {data.attributes.airline}</p>
                </div>
                <div className="ticket-body">
                  <p>Outbound</p>
                  <div className="flex-row ticket-info-section">
                    <img
                      src={`../assets/airline-logos/${data.attributes.airline.split(' - ').pop()}.png`}
                      alt="flight airline logo"
                      className="flight-airline-logo"
                    />
                    <div className="flex-row  ticket-flight-details">
                      <div className="flex-column">
                        <FaPlane className="ticket-plane-icon take-off-icon" />
                        <p>{data.attributes.departure1}</p>
                        <p>{'14-12-2024'}</p>
                      </div>
                      <div className="flight-divider"></div>
                      <div className="flex-column">
                        <FaPlane className="ticket-plane-icon land-icon" />
                        <p>{data.attributes.arrival1}</p>
                        <p>{'14-12-2024'}</p>
                      </div>
                    </div>
                    <div className="flex-column ticket-details">
                      <p>Ticket Price</p>
                      <p>&pound;{data.attributes.fare}</p>
                      <Link to={'/request-callback'} className="book-now">
                        Book Now
                      </Link>
                      <p>Limited seats...</p>
                      <p>Need Help To Reserve</p>
                      <a href="callto:+92081442561">
                        <FaPhoneAlt /> <span>0208 144 2561</span>
                      </a>
                      <a>
                        <FaPhoneAlt />
                        <span>Request callback</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <section>
        <div className="tickets_data">
          <div className="ticket_data">
            <div className="ticket_data_head">
              <h3>Why Book with us over the phone</h3>
            </div>
            <div className="ticket_p">
              <p>
                There are some special fares available over the phone only. So
                why not pick up the phone and give us a call. We have special
                fares you will not get anywhere else.
              </p>
              <p>All Calls Are Answered on Top Priority</p>
              <p>
                Call now to check current availability of our best buy fares.
              </p>
            </div>
          </div>
          <div className="ticket_form">
            <div className="ticket_form_head">
              <h3>Enquire Now:</h3>
            </div>

            <form action="" className="ticket_form_data">
              <div className="form_data">
                <div className="form_input">
                  <label htmlFor="name">First & Last Name (*)</label>
                  <input type="text" />
                </div>
                <div className="form_input">
                  <label htmlFor="name">E-mail (*)</label>
                  <input type="text" />
                </div>
                <div className="form_input">
                  <label htmlFor="name">First & Last Name(*)</label>
                  <input type="text" />
                </div>
              </div>
              <div className="form_btn">
                <a href="#">Book now</a>
              </div>
            </form>
          </div>
        </div>

        <div className="tickets_clients">
          <h2>Your selected flight itinerary</h2>
        </div>
        {/* 
        <div className="clients_tickets">
          <div className="client_ticket">
            <div className="clients">
              <div className="clients_links">
                <div className="client_logo">
                  <a href="#">
                    <img src={emirates} alt="client image" />
                  </a>
                  <a href="#">
                    <p>Royal Air Maroc - AT</p>
                  </a>
                </div>
                <div className="destination_location">
                  <FaPlane className="icon" />
                  <a href="#">
                    <h5>London Heathrow</h5>
                    <p>Wed,Nov,6</p>
                  </a>
                </div>
                <div className="destination_country">
                  <a href="#">
                    <FaPlane className="icons" />
                    <h2>ACCRA</h2>
                  </a>
                </div>
              </div>
              <div className="clients_links">
                <div className="client_logo">
                  <a href="#">
                    <img src={emirates} alt="client image" />
                  </a>
                  <a href="#">
                    <p>Royal Air Maroc - AT</p>
                  </a>
                </div>
                <div className="destination_location">
                  <FaPlane className="icon" />
                  <a href="#">
                    <h5>London Heathrow</h5>
                    <p>Wed,Nov,6</p>
                  </a>
                </div>
                <div className="destination_country">
                  <a href="#">
                    <FaPlane className="icons" />
                    <h2>ACCRA</h2>
                  </a>
                </div>
              </div>
            </div>
            <div className="ticket_price">
              <div className="price_box">
                <div>
                  <p className="price">&pound;490</p>
                  <p>Per Person</p>
                  <p>Call Now:</p>
                  <a href="tel:02081442561" className="a">
                    02081442561
                  </a>
                  <p>Extremely Limited Availablity</p>
                  <div className="form_btn_top">
                    <a href="#">Call Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </section>
    </div>
  );
}
