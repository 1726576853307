import '../styles/index.css';

export function Input(_props: {
  label: string;
  type: string;
  name: string;
  isRequired: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  value: string;
}) {
  return (
    <div>
      <label htmlFor={_props.name} className="label">
        {_props.label}
      </label>
      <input
        type={_props.type}
        id={_props.name}
        name={_props.name}
        className="input"
        required={_props.isRequired}
        onChange={e => _props.onChange(e)}
        value={_props.value}
      />
    </div>
  );
}
