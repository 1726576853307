import {Link} from 'react-router-dom';
import './admin-styles/adminLayout.css';

export function AdminLayout(_props: {children: JSX.Element}) {
  return (
    <div className="admin-layout">
      <aside className="admin-sidebar">
        <h2>Edreams</h2>
        <nav>
          <ul>
            <li>
              <Link to="/admin/dashboard">Callback Requests</Link>
            </li>
            <li>
              <Link to="/admin/countries">Country Ads</Link>
            </li>
            <li>
              <Link to="/admin/upload-file">Upload file</Link>
            </li>
          </ul>
        </nav>
      </aside>

      <main className="admin-content">{_props.children}</main>
    </div>
  );
}
