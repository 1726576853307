import {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import '../styles/navbar.css';
import {
  FaChevronDown,
  FaChevronUp,
  FaPhoneAlt,
  FaBars,
  FaArrowLeft,
  FaRegUserCircle,
  FaAngleDown,
} from 'react-icons/fa';
import logo from '../assets/logo.webp';

export function NavBar() {
  const [isActive, setIsActive] = useState(false); // Controls entire navbar (for mobile view)
  const [isDropdownActive, setIsDropdownActive] = useState(false); // Controls the dropdown menu

  const navbarRef = useRef<HTMLDivElement | null>(null); // Ref for navbar
  const isAdmin = localStorage.getItem('userRole') === 'admin';
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const token = localStorage.getItem('token');

  const toggleProfileDropdown = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  const closeProfileDropdown = (e: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node)
    ) {
      setIsProfileOpen(false);
    }
  };

  const handleScroll = () => {
    if (isActive) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isActive]);

  useEffect(() => {
    // Add event listener for clicks outside
    document.addEventListener('mousedown', closeProfileDropdown);
    return () => {
      // Cleanup event listener
      document.removeEventListener('mousedown', closeProfileDropdown);
    };
  }, []);

  const toggleNavbar = () => {
    setIsActive(!isActive);
  };

  const toggleDropdown = () => {
    setIsDropdownActive(!isDropdownActive);
  };

  // Close dropdown when clicking outside of it
  const handleClickOutside = (event: MouseEvent) => {
    if (
      navbarRef.current &&
      !navbarRef.current.contains(event.target as Node)
    ) {
      setIsDropdownActive(false);
      setIsActive(false); // Optionally close the navbar on click outside
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSignOut = async () => {
    setIsActive(false);

    try {
      // Retrieve the authorization token from local storage
      const token = localStorage.getItem('token');

      if (!token) {
        alert('Authorization token not found. Please log in again.');
        return;
      }

      // Make the sign-out API request
      const response = await fetch(
        'https://dream-travels-backend-9eaa9bf82980.herokuapp.com/api/v1/sign_out',
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (!response.ok) {
        throw new Error('Sign-out failed. Please try again.');
      }

      localStorage.removeItem('token');
      alert('You have been signed out successfully.');
    } catch (error) {
      console.error('Error signing out:', error);
      alert('An error occurred while signing out. Please try again.');
    }
  };

  const onNavItemClick = () => {
    setIsActive(false);
  };

  return (
    <header>
      <div className="logo-section container">
        <div className=" menu-icons">
          <FaArrowLeft
            className={`cross ${isActive ? 'is-active' : ''}`}
            onClick={toggleNavbar}
          />
          <FaBars
            className={`bars ${isActive ? 'is-active' : ''}`}
            onClick={toggleNavbar}
          />
        </div>
        <div>
          <img
            src={logo}
            alt="dreams travels logo"
            className="dreams-travels-logo"
          />
        </div>
        <a href="tel:+442081442561">
          <FaPhoneAlt className="nav-call-icon" />
        </a>
        <div className={`nav-contact-details`}>
          <span>Call out travel experts at</span>
          <div className="nav-phone-num">
            <FaPhoneAlt />
            <a href="tel:+442081442561">0208 144 2561</a>
          </div>
          <span>Timing 8:00am to 11:30pm Week days</span>
        </div>
      </div>
      <div className="bg-primary">
        <nav
          ref={navbarRef}
          className={`navbar-menu container ${isActive ? 'is-active' : ''}`}
          role="navigation"
          aria-label="main navigation">
          <div className="navbar-start">
            <Link
              className="navbar-item custom-navbar-item"
              to="/"
              onClick={onNavItemClick}>
              Home
            </Link>

            {/* Destinations dropdown */}
            <div
              className={`navbar-item custom-navbar-item ${
                isDropdownActive ? 'is-active' : ''
              } `}
              onClick={toggleDropdown}>
              <div className="dropdown-toggle">
                <span>Destinations</span>
                {isDropdownActive ? <FaChevronUp /> : <FaChevronDown />}
              </div>
              <div
                className={`navbar-dropdown custom-dropdown ${
                  isDropdownActive ? 'is-active' : ''
                }`}>
                <div className="columns custom-columns">
                  <div className="column is-one-quarter">
                    <div className="dropdown-content">
                      <p className="menu-label">India Top Destinations</p>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/amritsar-AMR">
                        Amritsar
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/delhi-DEL">
                        Delhi
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/mumbai-BOM">
                        Mumbai
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/goa-GOI">
                        Goa
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/hyderabad-HYD">
                        Hyderabad
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/ahmedabad-AMD">
                        Ahmedabad
                      </Link>
                    </div>
                  </div>

                  <div className="column is-one-quarter">
                    <div className="dropdown-content">
                      <p className="menu-label">Africa</p>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/abuja-ABV">
                        Abuja
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/abidjan-ABJ">
                        Abidjan
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/lagosLOS">
                        Lagos
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/accra-ACC">
                        Accra
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/luanda-LAD">
                        Luanda
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/douala-DLA">
                        Douala
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/mombasa-MBA">
                        Mombasa
                      </Link>
                    </div>
                  </div>

                  {/* Asia dropdown */}
                  <div className="column is-one-quarter">
                    <div className="dropdown-content">
                      <p className="menu-label">Asia</p>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/bangkok-BKK">
                        Bangkok
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/beijing-PEK">
                        Beijing
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/cebu-CEB">
                        Cebu
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/dubai-DXB">
                        Dubai
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/goa-GOI">
                        Goa
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/jeddah-JED">
                        Jeddah
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/singapore-SIN">
                        Singapore
                      </Link>
                    </div>
                  </div>

                  {/* Australia dropdown */}
                  <div className="column is-one-quarter">
                    <div className="dropdown-content">
                      <p className="menu-label">Australia</p>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/agana-GUM">
                        Agana
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/brisbane-BNE">
                        Brisbane
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/cairns-CNS">
                        Cairns
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/nelson-NSN">
                        Nelson
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/noumea-NOU">
                        Noumea
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/perth-PER">
                        Perth
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/sydney-SYD">
                        Sydney
                      </Link>
                    </div>
                  </div>

                  {/* America dropdown */}
                  <div className="column is-one-quarter">
                    <div className="dropdown-content">
                      <p className="menu-label">America</p>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/newyork-NYC">
                        New York
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/antigua-ANU">
                        Antigua
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/boston-BOS">
                        Boston
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/bogota-BOG">
                        Bogota
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/cali-CLO">
                        Cali
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/kingston-KIN">
                        Kingston
                      </Link>
                      <Link
                        className="dropdown-item custom-dropdown-item"
                        to="/flight/liberia-LIR">
                        Liberia
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Link
              className="navbar-item custom-navbar-item"
              to="/about-us"
              onClick={onNavItemClick}>
              About Us
            </Link>
            <Link
              className="navbar-item custom-navbar-item"
              to="/flight"
              onClick={onNavItemClick}>
              Flight
            </Link>
            <Link
              className="navbar-item custom-navbar-item"
              to="/contact-us"
              onClick={onNavItemClick}>
              Contact Us
            </Link>
            <Link
              className="navbar-item custom-navbar-item"
              to="/request-callback"
              onClick={onNavItemClick}>
              Call Back
            </Link>
            <Link
              className="navbar-item custom-navbar-item"
              to="/register"
              onClick={onNavItemClick}>
              Account
            </Link>
            <button
              type="button"
              className="navbar-item custom-navbar-item logout-for-mobile"
              onClick={handleSignOut}>
              Logout
            </button>
          </div>

          {token && (
            <div className="profile-dropdown" ref={dropdownRef}>
              <div className="dropdown-trigger" onClick={toggleProfileDropdown}>
                <FaRegUserCircle className="user-icon" />
                <span className="username">Account</span>
                <FaAngleDown />
              </div>
              {isProfileOpen && (
                <div className="profile-dropdown-menu">
                  {isAdmin && (
                    <div>
                      <Link
                        to={'/admin'}
                        className="flex-row admin-panel-link"
                        target="_blank">
                        <p className="dropdown-item">Admin </p>
                      </Link>
                    </div>
                  )}
                  <button className="dropdown-item" onClick={handleSignOut}>
                    Logout
                  </button>
                </div>
              )}
            </div>
          )}
        </nav>
      </div>
    </header>
  );
}
