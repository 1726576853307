import {useParams} from 'react-router-dom';
import {Services, Contact, Header, Flights} from '../components';
import {useEffect, useState} from 'react';

type flightsData = {
  id: string;
  type: string;
  attributes: {
    airline: '';
    arrival: '';
    departure: '';
    fare: '';
  };
};

export function Flight() {
  const {city} = useParams();
  const [flightData, setFlightData] = useState<flightsData[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://dream-travels-backend-9eaa9bf82980.herokuapp.com/api/v1/one_way_flights/searchByCity?arrival=${city?.split('-').pop()}`,
        );
        const data = await response.json();
        setFlightData(data.data);
      } catch (error) {
        console.error('Error fetching flight data:', error);
      }
    };

    fetchData();
  }, [city]);

  return (
    <main>
      <Header />
      <Flights flightData={flightData} />
      <Services />
      <Contact />
    </main>
  );
}
