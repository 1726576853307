import '../styles/pages/aboutus.css';

import {Services} from '../components';

export function AboutUs() {
  return (
    <main>
      <Services />
      <section className="info-section">
        <div className="container">
          <h2>General information</h2>
          <p>
            Our Association: Dreams Travels is not the sovereign travel agency,
            it is marketing brand of one of well-established UK based companies,
            Dreams Travels, which is registered company under the UK law
          </p>
        </div>
      </section>
    </main>
  );
}
