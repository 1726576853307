import '../styles/pages/account.css';
import {Input} from '../components';
import {useState} from 'react';
import {apiRequest} from '../utils/apiRequests';
import {useNavigate} from 'react-router-dom';

const signUpInitialState = {
  username: '',
  email: '',
  cell_no: '',
  post_code: '',
  door_no: '',
  password: '',
  password_confirmation: '',
};

const loginInitialState = {
  email: '',
  password: '',
};

export function Account() {
  const [active, setActive] = useState('register');
  const [signUp, setSingUp] = useState(signUpInitialState);
  const [login, setLogin] = useState(loginInitialState);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  function handleSignUpInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSingUp({...signUp, [e.target.name]: e.target.value});
  }

  function handleLoginInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setLogin({...login, [e.target.name]: e.target.value});
  }

  const handleActive = function (tab: string) {
    setActive(tab);
  };

  const handleSignUpSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setSingUp(signUpInitialState);

    const body = JSON.stringify({user: signUp});

    const {message, response} = await apiRequest(
      'https://dream-travels-backend-9eaa9bf82980.herokuapp.com/api/v1/sign_up',
      body,
    );

    if (response?.status === 401) {
      alert(message?.error);
    } else if (response?.status !== 200) {
      alert(message.status.message);
    } else {
      navigate('/');
    }

    setLoading(false);
  };

  const handleLoginSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setLogin(loginInitialState);

    const body = JSON.stringify({user: login});

    const {message, response, role} = await apiRequest(
      'https://dream-travels-backend-9eaa9bf82980.herokuapp.com/api/v1/sign_in',
      body,
    );

    if (response?.status === 401) {
      alert(message?.error);
    } else if (response?.status !== 200) {
      alert(message?.status?.message);
    } else {
      localStorage.setItem('userRole', role);
      navigate('/');
    }
    setLoading(false);
  };

  return (
    <main className="container register-main">
      <div className="register-btns">
        <a
          className={`${active === 'login' ? 'active' : ''} registration-btn`}
          onClick={() => handleActive('login')}>
          Login
        </a>
        <a
          className={`${active === 'register' ? 'active' : ''} registration-btn`}
          onClick={() => handleActive('register')}>
          Register
        </a>
      </div>
      {active === 'register' && (
        <form onSubmit={handleSignUpSubmit} className="registration-form">
          <Input
            label={'Username'}
            type={'text'}
            name={'username'}
            isRequired={true}
            onChange={handleSignUpInputChange}
            value={signUp.username}
          />
          <Input
            label={'Email'}
            type={'email'}
            name={'email'}
            isRequired={true}
            onChange={handleSignUpInputChange}
            value={signUp.email}
          />
          <Input
            label={'Cell No'}
            type={'text'}
            name={'cell_no'}
            isRequired={true}
            onChange={handleSignUpInputChange}
            value={signUp.cell_no}
          />
          <Input
            label={'Post Code'}
            type={'text'}
            name={'post_code'}
            isRequired={true}
            onChange={handleSignUpInputChange}
            value={signUp.post_code}
          />
          <Input
            label={'Door No'}
            type={'text'}
            name={'door_no'}
            isRequired={true}
            onChange={handleSignUpInputChange}
            value={signUp.door_no}
          />
          <Input
            label={'Password'}
            type={'password'}
            name={'password'}
            isRequired={true}
            onChange={handleSignUpInputChange}
            value={signUp.password}
          />
          <Input
            label={'Confirm Password'}
            type={'password'}
            name={'password_confirmation'}
            isRequired={true}
            onChange={handleSignUpInputChange}
            value={signUp.password_confirmation}
          />
          <button
            type="submit"
            className={`submit-btn ${loading ? 'disabled-button' : ''}`}>
            Registration
          </button>
        </form>
      )}
      {active === 'login' && (
        <form
          action="submit"
          onSubmit={handleLoginSubmit}
          className="registration-form">
          <Input
            label={'Username or e-mail'}
            type={'text'}
            name={'email'}
            isRequired={true}
            onChange={handleLoginInputChange}
            value={login.email}
          />

          <Input
            label={'Password'}
            type={'password'}
            name={'password'}
            isRequired={true}
            onChange={handleLoginInputChange}
            value={login.password}
          />
          <button
            type="submit"
            className={`submit-btn ${loading ? 'disabled-button' : ''}`}>
            Sign in
          </button>
        </form>
      )}
    </main>
  );
}
